<template lang="pug">
v-container(fluid)
  //- Dialog profile
  v-dialog(
    fullscreen,
    persistent,
    :value="dialogProfile",
    max-width="600px",
    transition="dialog-bottom-transition"
  )
    div(style="position: relative; height: 100%")
      profile-dialog(@close="dialogProfile = false")
  //- Dialog
  //- Dialog profile
  v-dialog(
    v-if="dialogProfile",
    v-model="dialogProfile",
    :fullscreen="isMobile",
    overlay-color="blue lighten-5",
    transition="dialog-bottom-transition",
    style="max-height: 100vh; overflow-y: auto"
  )
    profile-dialog(@close="closeProfile", style="z-index: 1")
  //- Dialog how user
  v-dialog(
    v-model="howUseDialog",
    :fullscreen="isMobile",
    transition="dialog-bottom-transition"
  )
    how-use.pa-10(
      @close="howUseDialog = false",
      :modal="true",
      style="background: white; height: 100%; z-index: 999"
    )
  //- Login
  v-dialog(
    max-width="500",
    v-model="loginDialog",
    v-if="loginDialog",
    overlay-color="blue lighten-5"
  )
    login(
      userType="bussiness",
      @close="loginDialog = false",
      @switchDialog="switchDialog('register')"
    )
  //- Register
  v-dialog(
    max-width="500",
    v-model="registerDialog",
    v-if="registerDialog",
    overlay-color="blue lighten-5"
  )
    register(
      userType="bussiness",
      @close="registerDialog = false",
      @switchDialog="switchDialog('login')"
    )
  //- Drawer
  v-navigation-drawer#core-navigation-drawer.custom-drawer(
    v-model="drawer",
    :light="isMobile",
    :dark="!isMobile",
    :expand-on-hover="expandOnHover",
    :right="$vuetify.rtl",
    :src="barImage",
    mobile-break-point="960",
    app="",
    mini-variant-width="80",
    :width="windowWidth < 600 ? windowWidth : 320",
    v-bind="$attrs"
  )
    template(v-slot:img="props")
      v-img(:gradient="`to bottom, ${barColor}`", v-bind="props")
    //- drawer header
    v-list-item(two-line="")
      v-list-item-content
        v-list-item-title.text-uppercase.font-weight-regular.display-2
          span.logo-mini {{ $t('sf') }}
          span.logo-normal {{ $t('title') }}
          .close-button.cursor-pointer(
            @click="$vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : $emit('input', !value)"
          )
            v-icon mdi-close-circle
    v-divider.mb-1
    v-col
    //- profile
    v-list(v-if="user", dense="", nav="")
      base-item(head, :item="itemsProfile", color="black")
    v-btn(
      v-else,
      expand="",
      nav="",
      text,
      large,
      :color="isMobile ? 'black' : 'white'",
      @click="openLoginDialog"
    )
      v-icon mdi-login
      | Login
    v-divider.mb-2
    v-list(expand="", nav="", style="height: 50%")
      //- items si es en mobile
      template(v-if="isMobile", v-for="(item, i) in itemsMobile")
        base-item(:key="`item-${i}`", :item="item")
      //- Cambiar perfil
      v-list-item(v-if="isMobile && user", to="/")
        v-list-item-icon
          img.bright(
            src="@/assets/icons/swap.png",
            width="auto",
            height="25px"
          )
        v-list-item-content
          v-list-item-title.v-list-item__text--main.black--text Become a Customer
      //-logout
      v-list-item(v-if="isMobile && user", @click="logout")
        v-list-item-icon
          img.bright(
            src="@/assets/images/icon-logout.png",
            width="auto",
            height="25px"
          )
        v-list-item-content
          v-list-item-title.v-list-item__text--main.black--text Log out
      //- items si es en desktop
      template(v-if="!isMobile", v-for="(item, i) in itemsDesktop")
        div(style="position: relative; top: 30%")
          base-item(
            :key="`item-${i}`",
            :item="item",
            style="position: relative; top: 30%"
          )
          v-alert.tutorial-alert(
            v-if="user && profile && profile.tutorialContributor && !isMobile && i === stepsTutorial",
            :color="tutorial[stepsTutorial].color"
          )
            v-row
              h3.headline {{ tutorial[stepsTutorial].title }}
              v-spacer
              v-btn.ml-auto.align-start(
                @click="stepsTutorial = 10",
                small,
                icon
              )
                v-icon mdi-close
            div
              | {{ tutorial[stepsTutorial].description }}
            v-divider.my-4.info(style="opacity: 0.22")
            v-checkbox(
              v-if="tutorial.length - 1 === stepsTutorial",
              v-model="showTutorial",
              label="Do not show this again",
              color="red",
              value="red",
              hide-details=""
            )
            .text-right
              v-btn(
                v-if="stepsTutorial > 0",
                color="white",
                text,
                small,
                @click="backStep"
              )
                | prev
              v-btn(color="white", text, small, @click="changeStep")
                span(v-if="tutorial.length - 1 === stepsTutorial") Finish
                span(v-else) next
</template>

<script>
import loaders from "@/plugins/mixins/loaders";
import profileDialog from "../../../../components/custom/dialogs/profileDialog";
import { auth } from "@/plugins/auth/firebase";
import Cookie from "js-cookie";
// Utilities
export default {
  name: "DashboardCoreDrawer",

  components: {
    profileDialog: profileDialog,
    login: () => import("@/views/pages/Login"),
    register: () => import("@/views/pages/Register"),
  },

  mixins: [loaders],

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    dialogProfile: false,
    loginDialog: false,
    registerDialog: false,
    tutorial: [
      {
        title: "Home",
        description:
          "This is where you begin your uploading process. Use the camera to begin capturing content.",
        color: "info",
      },
      {
        title: "My Uploads",
        description:
          "All content that have been uploaded by you will be stored here.",
        color: "warning",
      },
      {
        title: "Notifications",
        description:
          "This is where all your latest notifications that requires attention can be found.",
        color: "error",
      },
      {
        title: "Become a Customer",
        description:
          "Here you can search, buy and download content from contributors.",
        color: "error",
      },
    ],
    stepsTutorial: 0,
    showTutorial: false,
  }),

  computed: {
    windowWidth() {
      return window.innerWidth;
    },

    barColor() {
      return this.$store.state.drawer.barColor;
    },
    barImage() {
      return this.$store.state.drawer.barImage;
    },
    profile() {
      return this.$store.state.users.profile;
    },

    user() {
      return this.$store.state.users.user;
    },

    isMobile() {
      return this.$store.getters["users/getIsMobile"];
    },

    itemsProfile() {
      return {
        altImg:
          this.profile && this.profile.image
            ? this.profile.image
            : require("@/assets/user.png"),
        icon: "mdi-account",
        title: "My Profile",
        to: "/contributor/profile",
      };
    },

    itemsDesktop() {
      if (this.user) {
        return [
          {
            altImg: require("@/assets/images/search-icon.png"),
            icon: "mdi-view-dashboard",
            title: "Home",
            to: "/contributor/#",
          },
          {
            altImg: require("@/assets/images/icon_video.png"),
            icon: "mdi-view-dashboard",
            title: "My Uploads",
            to: "/contributor/videos",
          },
          {
            altImg: require("@/assets/images/icon4.png"),
            icon: "mdi-view-dashboard",
            title: "Notifications",
            to: "/contributor/notifications",
          },
          {
            altImg: require("@/assets/icons/swap.png"),
            icon: "mdi-view-dashboard",
            title: "Become a Customer",
            to: "/",
          },
        ];
      } else {
        return [
          {
            altImg: require("@/assets/images/search-icon.png"),
            icon: "mdi-view-dashboard",
            title: "Search",
            to: "/",
          },
          {
            altImg: require("@/assets/images/icon3.png"),
            icon: "mdi-view-dashboard",
            title: "Map",
            to: "/search",
          },
        ];
      }
    },

    itemsMobile() {
      if (this.user) {
        return [
          {
            altImg: require("@/assets/images/icon-faq.png"),
            icon: "mdi-view-dashboard",
            title: "FAQ's",
            to: "/contributor/faq",
          },
          {
            altImg: require("@/assets/images/icon-about.png"),
            icon: "mdi-view-dashboard",
            title: "About Us",
            to: "/contributor/about-us",
          },
          {
            altImg: require("@/assets/images/icon-contact.png"),
            icon: "mdi-view-dashboard",
            title: "Contact Us",
            to: "/contributor/contact-us",
          },
        ];
      } else {
        return [
          {
            altImg: require("@/assets/images/icon-faq.png"),
            icon: "mdi-view-dashboard",
            title: "FAQ's",
            to: "/faq",
          },
          {
            altImg: require("@/assets/images/icon-about.png"),
            icon: "mdi-view-dashboard",
            title: "About Us",
            to: "/about-us",
          },
          {
            altImg: require("@/assets/images/icon-contact.png"),
            icon: "mdi-view-dashboard",
            title: "Contact Us",
            to: "/contact-us/contact",
          },
          {
            altImg: require("@/assets/images/icon-terms.png"),
            icon: "mdi-view-dashboard",
            title: "Terms & Conditions",
            to: "/terms-and-conditions",
          },
        ];
      }
    },

    drawer: {
      get() {
        return this.$store.state.drawer.drawer;
      },
      set(val) {
        // console.log(val, this)
        this.$store.dispatch("drawer/set_drawer", val);
      },
    },
  },

  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    },

    user: function (newVal) {
      if (newVal) {
        this.getProfile();
      }
    },
  },

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.user) {
      this.getProfile();
    }
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    backStep() {
      const vm = this;
      vm.stepsTutorial--;
    },
    changeStep() {
      const vm = this;
      vm.stepsTutorial++;
      if (vm.stepsTutorial >= vm.tutorial.length) {
        vm.stepsTutorial = 8;
        if (vm.showTutorial) {
          const profile = {
            tutorialContributor: false,
          };
          vm.$store.dispatch("users/updateProfile", profile);
        }
      }
    },
    switchDialog(to) {
      const vm = this;
      switch (to) {
        case "login":
          vm.registerDialog = false;
          vm.loginDialog = true;
          break;
        case "register":
          vm.loginDialog = false;
          vm.registerDialog = true;
          break;
      }
    },
    openLoginDialog() {
      this.loginDialog = true;
    },
    openRegisterDialog() {
      this.registerDialog = true;
    },
    async logout() {
      const vm = this;
      await auth.signOut();
      await Cookie.remove("access_token");
      vm.$store.dispatch("users/clearUser").then(function () {
        vm.$router.replace("/");
      });
    },
    setDrawer(payload) {
      this.$store.dispatch("drawer/set_drawer", payload);
    },
    handleResize() {
      if (window.innerWidth <= 720) {
        this.$store.commit("users/SET_MOBILE", true);
      } else {
        this.$store.commit("users/SET_MOBILE", false);
      }
    },
    validateProfile(profile) {
      const vm = this;
      if (!profile) {
        vm.dialogProfile = true;
      }
    },
    getProfile() {
      const vm = this;
      vm.$store.dispatch("users/loadProfile").then(function (res) {
        vm.validateProfile(res);
      });
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important

      .v-list-group--sub-group
        display: block !important

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
        margin-right: 24px
        margin-left: 12px !important

        +rtl()
        margin-left: 24px
        margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
        padding-left: 8px

        +rtl()
        padding-right: 8px

      .v-list-group__header
        +ltr()
        padding-right: 0

        +rtl()
        padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
          margin-right: 8px

          +rtl()
          margin-left: 8px
</style>
